.iconfont-mixin() {
  display: inline-block;
  color: @icon-color;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em; // for SVG icon, see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    line-height: 1;
  }

  svg {
    display: inline-block;
  }

  &::before {
    display: none; // dont display old icon.
  }

  & &-icon {
    display: block;
  }
}
