@import './index';

.@{form-prefix-cls}-horizontal {
  .@{form-item-prefix-cls}-label {
    flex-grow: 0;
  }
  .@{form-item-prefix-cls}-control {
    flex: 1 1 0;
  }
}
