.fade-motion(@className, @keyframeName) {
  .make-motion(@className, @keyframeName);
  .@{className}-enter,
  .@{className}-appear {
    opacity: 0;
    animation-timing-function: linear;
  }
  .@{className}-leave {
    animation-timing-function: linear;
  }
}

.fade-motion(fade, antFade);

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
