@dialog-prefix-cls: ~'@{ant-prefix}-modal';
@table-prefix-cls: ~'@{ant-prefix}-table';

.@{dialog-prefix-cls} {
  .reset-component();
  .modal-mask();

  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;

  &-wrap {
    z-index: @zindex-modal;
  }

  &-title {
    margin: 0;
    color: @modal-heading-color;
    font-weight: 500;
    font-size: @modal-header-title-font-size;
    line-height: @modal-header-title-line-height;
    word-wrap: break-word;
  }

  &-content {
    position: relative;
    background-color: @modal-content-bg;
    background-clip: padding-box;
    border: 0;
    border-radius: @border-radius-base;
    box-shadow: @shadow-2;
    pointer-events: auto;
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: @zindex-popup-close;
    padding: 0;
    color: @modal-close-color;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;

    &-x {
      display: block;
      width: @modal-header-close-size;
      height: @modal-header-close-size;
      font-size: @font-size-lg;
      font-style: normal;
      line-height: @modal-header-close-size;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
    }

    &:focus,
    &:hover {
      color: @icon-color-hover;
      text-decoration: none;
    }
  }

  &-header {
    padding: @modal-header-padding;
    color: @text-color;
    background: @modal-header-bg;
    border-bottom: @modal-header-border-width @modal-header-border-style
      @modal-header-border-color-split;
    border-radius: @border-radius-base @border-radius-base 0 0;
  }

  &-body {
    padding: @modal-body-padding;
    font-size: @font-size-base;
    line-height: @line-height-base;
    word-wrap: break-word;
  }

  &-footer {
    padding: @modal-footer-padding-vertical @modal-footer-padding-horizontal;
    text-align: right;
    background: @modal-footer-bg;
    border-top: @modal-footer-border-width @modal-footer-border-style
      @modal-footer-border-color-split;
    border-radius: 0 0 @border-radius-base @border-radius-base;

    button + button {
      margin-bottom: 0;
      margin-left: 8px;
    }
  }

  &-open {
    overflow: hidden;
  }
}

.@{dialog-prefix-cls}-centered {
  text-align: center;
  &::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }
  .@{dialog-prefix-cls} {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

@media (max-width: @screen-sm-max) {
  .@{dialog-prefix-cls} {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .@{dialog-prefix-cls}-centered {
    .@{dialog-prefix-cls} {
      flex: 1;
    }
  }
}
