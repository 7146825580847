@import '../../style/themes/index';
@import '../../style/mixins/index';

@skeleton-prefix-cls: ~'@{ant-prefix}-skeleton';
@skeleton-avatar-prefix-cls: ~'@{skeleton-prefix-cls}-avatar';
@skeleton-title-prefix-cls: ~'@{skeleton-prefix-cls}-title';
@skeleton-paragraph-prefix-cls: ~'@{skeleton-prefix-cls}-paragraph';

.@{skeleton-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-header {
    .@{skeleton-prefix-cls}-rtl & {
      padding-right: 0;
      padding-left: 16px;
    }
  }

  // With active animation
  &.@{skeleton-prefix-cls}-active {
    & .@{skeleton-prefix-cls}-content {
      .@{skeleton-title-prefix-cls},
      .@{skeleton-paragraph-prefix-cls} > li {
        .@{skeleton-prefix-cls}-rtl& {
          animation-name: ~'@{skeleton-prefix-cls}-loading-rtl';
        }
      }
    }

    .@{skeleton-avatar-prefix-cls} {
      .@{skeleton-prefix-cls}-rtl& {
        animation-name: ~'@{skeleton-prefix-cls}-loading-rtl';
      }
    }
  }
}

@keyframes ~"@{skeleton-prefix-cls}-loading-rtl" {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
