@import '../themes/index';
@import '../mixins/iconfont';

.@{iconfont-css-prefix} {
  .iconfont-mixin();

  &[tabindex] {
    cursor: pointer;
  }
}

.@{iconfont-css-prefix}-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.@{iconfont-css-prefix}-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
