.@{steps-prefix-cls}-dot,
.@{steps-prefix-cls}-dot.@{steps-prefix-cls}-small {
  .@{steps-prefix-cls}-item {
    &-title {
      line-height: @line-height-base;
    }
    &-tail {
      top: @steps-dot-top;
      width: 100%;
      margin: 0 0 0 (@steps-description-max-width / 2);
      padding: 0;

      &::after {
        width: ~'calc(100% - 20px)';
        height: 3px;
        margin-left: 12px;
      }
    }
    &:first-child .@{steps-prefix-cls}-icon-dot {
      left: 2px;
    }
    &-icon {
      width: @steps-dot-size;
      height: @steps-dot-size;
      margin-left: 67px;
      padding-right: 0;
      line-height: @steps-dot-size;
      background: transparent;
      border: 0;

      .@{steps-prefix-cls}-icon-dot {
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        transition: all 0.3s;
        /* expand hover area */
        &::after {
          position: absolute;
          top: -12px;
          left: -26px;
          width: 60px;
          height: 32px;
          background: fade(@black, 0.1%);
          content: '';
        }
      }
    }
    &-content {
      width: @steps-description-max-width;
    }
    &-process .@{steps-prefix-cls}-item-icon {
      position: relative;
      top: -1px;
      width: @steps-current-dot-size;
      height: @steps-current-dot-size;
      line-height: @steps-current-dot-size;
      background: none;
    }
    &-process .@{steps-prefix-cls}-icon {
      &:first-child .@{steps-prefix-cls}-icon-dot {
        left: 0;
      }
    }
  }
}

.@{steps-prefix-cls}-vertical.@{steps-prefix-cls}-dot {
  .@{steps-prefix-cls}-item-icon {
    margin-top: 8px;
    margin-left: 0;
    background: none;
  }
  // https://github.com/ant-design/ant-design/issues/18354
  .@{steps-prefix-cls}-item > .@{steps-prefix-cls}-item-container > .@{steps-prefix-cls}-item-tail {
    top: 2px;
    left: -9px;
    margin: 0;
    padding: 22px 0 4px;
  }
  .@{steps-prefix-cls}-item:first-child .@{steps-prefix-cls}-icon-dot {
    left: 0;
  }
  .@{steps-prefix-cls}-item-content {
    width: inherit;
  }
  .@{steps-prefix-cls}-item-process
    .@{steps-prefix-cls}-item-container
    .@{steps-prefix-cls}-item-icon
    .@{steps-prefix-cls}-icon-dot {
    left: -2px;
  }
}
