@import './index.less';

@table-prefix-cls: ~'@{ant-prefix}-table';
@input-prefix-cls: ~'@{ant-prefix}-input';

.@{transfer-prefix-cls}-customize-list {
  .@{transfer-prefix-cls}-list {
    flex: 1 1 50%;
    width: auto;
    height: auto;
    min-height: @transfer-list-height;
  }

  // =================== Hook Components ===================
  .@{table-prefix-cls}-wrapper {
    .@{table-prefix-cls}-small {
      border: 0;
      border-radius: 0;

      > .@{table-prefix-cls}-content {
        // Header background color
        > .@{table-prefix-cls}-body > table > .@{table-prefix-cls}-thead > tr > th {
          background: @table-header-bg;
        }

        .@{table-prefix-cls}-row:last-child td {
          border-bottom: @border-width-base @border-style-base @border-color-split;
        }
      }

      .@{table-prefix-cls}-body {
        margin: 0;
      }
    }

    .@{table-prefix-cls}-pagination.@{ant-prefix}-pagination {
      margin: 16px 0 4px;
    }
  }
  .@{input-prefix-cls} {
    &[disabled] {
      background-color: transparent;
    }
  }
}
