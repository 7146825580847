@import '../themes/index';

.motion-common(@duration: @animation-duration-base) {
  animation-duration: @duration;
  animation-fill-mode: both;
}

.motion-common-leave(@duration: @animation-duration-base) {
  animation-duration: @duration;
  animation-fill-mode: both;
}

.make-motion(@className, @keyframeName, @duration: @animation-duration-base) {
  .@{className}-enter,
  .@{className}-appear {
    .motion-common(@duration);

    animation-play-state: paused;
  }
  .@{className}-leave {
    .motion-common-leave(@duration);

    animation-play-state: paused;
  }
  .@{className}-enter.@{className}-enter-active,
  .@{className}-appear.@{className}-appear-active {
    animation-name: ~'@{keyframeName}In';
    animation-play-state: running;
  }
  .@{className}-leave.@{className}-leave-active {
    animation-name: ~'@{keyframeName}Out';
    animation-play-state: running;
    pointer-events: none;
  }
}
