@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './status';

@dropdown-prefix-cls: ~'@{ant-prefix}-dropdown';

.@{dropdown-prefix-cls} {
  .reset-component();

  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: @zindex-dropdown;
  display: block;

  &::before {
    position: absolute;
    top: -@popover-distance + @popover-arrow-width;
    right: 0;
    bottom: -@popover-distance + @popover-arrow-width;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: ' ';
  }

  &-wrap {
    position: relative;

    .@{ant-prefix}-btn > .@{iconfont-css-prefix}-down {
      font-size: 10px;
    }

    .@{iconfont-css-prefix}-down::before {
      transition: transform 0.2s;
    }
  }

  &-wrap-open {
    .@{iconfont-css-prefix}-down::before {
      transform: rotate(180deg);
    }
  }

  &-hidden,
  &-menu-hidden {
    display: none;
  }

  // Offset the popover to account for the dropdown arrow
  &-show-arrow&-placement-topCenter,
  &-show-arrow&-placement-topLeft,
  &-show-arrow&-placement-topRight {
    padding-bottom: @popover-distance;
  }

  &-show-arrow&-placement-bottomCenter,
  &-show-arrow&-placement-bottomLeft,
  &-show-arrow&-placement-bottomRight {
    padding-top: @popover-distance;
  }

  // Arrows
  // .popover-arrow is outer, .popover-arrow:after is inner

  &-arrow {
    position: absolute;
    z-index: 1; // lift it up so the menu wouldn't cask shadow on it
    display: block;
    width: sqrt(@popover-arrow-width * @popover-arrow-width * 2);
    height: sqrt(@popover-arrow-width * @popover-arrow-width * 2);
    background: transparent;
    border-style: solid;
    border-width: (sqrt(@popover-arrow-width * @popover-arrow-width * 2) / 2);
    transform: rotate(45deg);
  }

  &-placement-topCenter > &-arrow,
  &-placement-topLeft > &-arrow,
  &-placement-topRight > &-arrow {
    bottom: @popover-distance - @popover-arrow-width + 2.2px;
    border-top-color: transparent;
    border-right-color: @popover-bg;
    border-bottom-color: @popover-bg;
    border-left-color: transparent;
    box-shadow: 3px 3px 7px fade(@black, 7%);
  }
  &-placement-topCenter > &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  &-placement-topLeft > &-arrow {
    left: 16px;
  }
  &-placement-topRight > &-arrow {
    right: 16px;
  }

  &-placement-bottomCenter > &-arrow,
  &-placement-bottomLeft > &-arrow,
  &-placement-bottomRight > &-arrow {
    top: @popover-distance - @popover-arrow-width + 2px;
    border-top-color: @popover-bg;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: @popover-bg;
    box-shadow: -2px -2px 5px fade(@black, 6%);
  }
  &-placement-bottomCenter > &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  &-placement-bottomLeft > &-arrow {
    left: 16px;
  }
  &-placement-bottomRight > &-arrow {
    right: 16px;
  }

  &-menu {
    position: relative;
    margin: 0;
    padding: @dropdown-edge-child-vertical-padding 0;
    text-align: left;
    list-style-type: none;
    background-color: @dropdown-menu-bg;
    background-clip: padding-box;
    border-radius: @border-radius-base;
    outline: none;
    box-shadow: @box-shadow-base;

    &-item-group-title {
      padding: 5px @control-padding-horizontal;
      color: @text-color-secondary;
      transition: all 0.3s;
    }

    &-submenu-popup {
      position: absolute;
      z-index: @zindex-dropdown;
      background: transparent;
      box-shadow: none;
      transform-origin: 0 0;

      ul,
      li {
        list-style: none;
      }

      ul {
        margin-right: 0.3em;
        margin-left: 0.3em;
      }
    }

    &-item,
    &-submenu-title {
      clear: both;
      margin: 0;
      padding: @dropdown-vertical-padding @control-padding-horizontal;
      color: @text-color;
      font-weight: normal;
      font-size: @dropdown-font-size;
      line-height: @dropdown-line-height;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;

      > .@{iconfont-css-prefix}:first-child,
      > a > .@{iconfont-css-prefix}:first-child,
      > span > .@{iconfont-css-prefix}:first-child {
        min-width: 12px;
        margin-right: 8px;
        font-size: @font-size-sm;
        vertical-align: -0.1em;
      }

      > a {
        display: block;
        margin: -5px -@control-padding-horizontal;
        padding: 5px @control-padding-horizontal;
        color: @text-color;
        transition: all 0.3s;
        &:hover {
          color: @text-color;
        }
      }

      > .@{iconfont-css-prefix} + span > a {
        color: @text-color;
        transition: all 0.3s;
        &:hover {
          color: @text-color;
        }
      }

      &:first-child {
        & when (@dropdown-edge-child-vertical-padding = 0) {
          border-radius: @border-radius-base @border-radius-base 0 0;
        }
      }

      &:last-child {
        & when (@dropdown-edge-child-vertical-padding = 0) {
          border-radius: 0 0 @border-radius-base @border-radius-base;
        }
      }

      &-selected,
      &-selected > a {
        color: @dropdown-selected-color;
        background-color: @item-active-bg;
      }

      &:hover {
        background-color: @item-hover-bg;
      }

      &-disabled {
        color: @disabled-color;
        cursor: not-allowed;

        &:hover {
          color: @disabled-color;
          background-color: @dropdown-menu-submenu-disabled-bg;
          cursor: not-allowed;
        }

        > a {
          position: relative;
          color: @disabled-color;
          pointer-events: none;

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: not-allowed;
            content: '';
          }
        }
      }

      &-divider {
        height: 1px;
        margin: 4px 0;
        overflow: hidden;
        line-height: 0;
        background-color: @border-color-split;
      }

      .@{dropdown-prefix-cls}-menu-submenu-expand-icon {
        position: absolute;
        right: @padding-xs;

        .@{dropdown-prefix-cls}-menu-submenu-arrow-icon {
          margin-right: 0 !important;
          color: @text-color-secondary;
          font-size: 10px;
          font-style: normal;
        }
      }
    }

    &-item-group-list {
      margin: 0 8px;
      padding: 0;
      list-style: none;
    }

    &-submenu-title {
      padding-right: @control-padding-horizontal + @font-size-sm;
    }

    &-submenu-vertical {
      position: relative;
    }

    &-submenu-vertical > & {
      position: absolute;
      top: 0;
      left: 100%;
      min-width: 100%;
      margin-left: 4px;
      transform-origin: 0 0;
    }

    &-submenu&-submenu-disabled .@{dropdown-prefix-cls}-menu-submenu-title {
      &,
      .@{dropdown-prefix-cls}-menu-submenu-arrow-icon {
        color: @disabled-color;
        background-color: @dropdown-menu-submenu-disabled-bg;
        cursor: not-allowed;
      }
    }

    // https://github.com/ant-design/ant-design/issues/19264
    &-submenu-selected &-submenu-title {
      color: @primary-color;
    }
  }

  &.slide-down-enter.slide-down-enter-active&-placement-bottomLeft,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomLeft,
  &.slide-down-enter.slide-down-enter-active&-placement-bottomCenter,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomCenter,
  &.slide-down-enter.slide-down-enter-active&-placement-bottomRight,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomRight {
    animation-name: antSlideUpIn;
  }

  &.slide-up-enter.slide-up-enter-active&-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active&-placement-topLeft,
  &.slide-up-enter.slide-up-enter-active&-placement-topCenter,
  &.slide-up-appear.slide-up-appear-active&-placement-topCenter,
  &.slide-up-enter.slide-up-enter-active&-placement-topRight,
  &.slide-up-appear.slide-up-appear-active&-placement-topRight {
    animation-name: antSlideDownIn;
  }

  &.slide-down-leave.slide-down-leave-active&-placement-bottomLeft,
  &.slide-down-leave.slide-down-leave-active&-placement-bottomCenter,
  &.slide-down-leave.slide-down-leave-active&-placement-bottomRight {
    animation-name: antSlideUpOut;
  }

  &.slide-up-leave.slide-up-leave-active&-placement-topLeft,
  &.slide-up-leave.slide-up-leave-active&-placement-topCenter,
  &.slide-up-leave.slide-up-leave-active&-placement-topRight {
    animation-name: antSlideDownOut;
  }
}

.@{dropdown-prefix-cls}-trigger,
.@{dropdown-prefix-cls}-link,
.@{dropdown-prefix-cls}-button {
  > .@{iconfont-css-prefix}.@{iconfont-css-prefix}-down {
    font-size: 10px;
    vertical-align: baseline;
  }
}

.@{dropdown-prefix-cls}-button {
  white-space: nowrap;

  &.@{ant-prefix}-btn-group
    > .@{ant-prefix}-btn:last-child:not(:first-child):not(.@{ant-prefix}-btn-icon-only) {
    padding-right: @padding-xs;
    padding-left: @padding-xs;
  }
}

// https://github.com/ant-design/ant-design/issues/4903
.@{dropdown-prefix-cls}-menu-dark {
  &,
  .@{dropdown-prefix-cls}-menu {
    background: @menu-dark-bg;
  }
  .@{dropdown-prefix-cls}-menu-item,
  .@{dropdown-prefix-cls}-menu-submenu-title,
  .@{dropdown-prefix-cls}-menu-item > a,
  .@{dropdown-prefix-cls}-menu-item > .@{iconfont-css-prefix} + span > a {
    color: @text-color-secondary-dark;
    .@{dropdown-prefix-cls}-menu-submenu-arrow::after {
      color: @text-color-secondary-dark;
    }
    &:hover {
      color: @text-color-inverse;
      background: transparent;
    }
  }
  .@{dropdown-prefix-cls}-menu-item-selected {
    &,
    &:hover,
    > a {
      color: @text-color-inverse;
      background: @primary-color;
    }
  }
}

@import './rtl';
