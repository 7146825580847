@import '../themes/index';

.reset-component() {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: @text-color;
  font-size: @font-size-base;
  font-variant: @font-variant-base;
  line-height: @line-height-base;
  list-style: none;
  font-feature-settings: @font-feature-settings-base;
}
